//health insurance applications page
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../context/UserContext";

import { handlePrint } from "../utils/generatePdf";
import { BiDetail } from "react-icons/bi";
import ApplicationDataGrid from "../components/data-grids/ApplicationDataGrid";

const ApplicationPage = () => {
  const { user } = useContext(UserContext);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isApplicationOpen, setIsApplicationOpen] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      if (!user) return;
      const token = user.token || localStorage.getItem("token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/application`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log(response.data);
        const applicationsWithIds = response.data.applications.map((app) => ({
          id: app._id,
          isNew: true,
          ...app.application.contact_information,
          health_insurance: app.application.health_insurance,
          ...app.application.current_health_insurance_info,
          ...app.application.additional_info,
          ...app.application.employer_info,
          ...app.application.sign_off_info,
        }));
        setApplications(applicationsWithIds);
      } catch (error) {
        console.error("Error fetching applications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [user]);

  const onSelectionModelChange = (newSelection) => {
    const selectedAppId = newSelection?.[0];
    if (selectedAppId) {
      const selectedApplication = applications.find(
        (app) => app.id === selectedAppId
      );
      setSelectedApplication(selectedApplication);
      markApplicationAsSeen(selectedApplication);
    } else {
      setSelectedApplication(null);
    }
  };

  const markApplicationAsSeen = (application) => {
    if (application && application.isNew) {
      const updatedApplications = applications.map((app) =>
        app.id === application.id ? { ...app, isNew: false } : app
      );
      setApplications(updatedApplications);
    }
  };



  const handleOpenModal = (application) => {
    setSelectedApplication(application);
    setIsApplicationOpen(application.isNew);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedApplication(null);
    setIsApplicationOpen(false);
    setModalOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "firstname", headerName: "Vorname", width: 200 },
    { field: "lastname", headerName: "Nachname", width: 200 },
    {
      field: "health_insurance",
      headerName: "Krankenversicherung",
      width: 400,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <>
          <button
            onClick={() => handleOpenModal(params.row)}
            className="bg-transparent hover:bg-[#0098D6] text-[#0098D6] hover:text-white border border-[#0098D6] hover:border-transparent rounded outline-none focus:outline-none transition duration-300 ease-in-out p-1 text-sm"
          >
           <div className="flex"> <BiDetail className="text-lg mr-2" />
           <span>Ansicht</span></div>
          </button>
        </>
      ),
    },
  ];

  return (
    <ApplicationDataGrid
      applications={applications}
      loading={loading}
      columns={columns}
      modalOpen={modalOpen}
      selectedApplication={selectedApplication}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      onSelectionModelChange={onSelectionModelChange}
      isApplicationOpen={isApplicationOpen}
      handlePrint={handlePrint}
    />
  );
};

export default ApplicationPage;
