import React, { useState, Suspense } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Applications = React.lazy(() => import("./Applications"));
const CompanyPage = React.lazy(() => import("./CompanyPage"));
// const CompanyDelete = React.lazy(() => import("./CompanyEdit"));
const Registration = React.lazy(() => import("../Pages/auth/Registration")); 
const AdminReg = React.lazy(() => import("../Pages/auth/AdminReg"));

const Admin = () => {
  const [tabValue, setTabValue] = useState(0);
  const [visitedTabs, setVisitedTabs] = useState({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setVisitedTabs((prevState) => ({
      ...prevState,
      [newValue]: true,
    }));
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="admin tabs">
        <Tab label="Alle Anträge" />
        <Tab label="Alle Krankenversicherungen" />
        {/* <Tab label="Company Edit" /> */}
        <Tab label="Registriere Krankenversicherung" />
        <Tab label="Registriere Admin Account" />
      </Tabs>
      <Suspense fallback={<div>Lade...</div>}>
        {" "}
        {tabValue === 0 && <Applications />}
        {tabValue === 1 && visitedTabs[1] && <CompanyPage />}
        {/* {tabValue === 1 && visitedTabs[1] && <CompanyDelete />} */}
        {tabValue === 2 && visitedTabs[2] && <Registration />}
        {tabValue === 3 && visitedTabs[3] && <AdminReg />}
      </Suspense>
    </div>
  );
};

export default Admin;
